
























































































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'Chem51LBProject1FS1QuantitativeObservations',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        unknown1: null,
        unknown2: null,
        unknown3: null,
        rowsExpertIR: [
          {
            functionalGroup: '',
            waveNumber: '',
            comment: '',
          },
        ],
        rowsExpertNMR: [
          {
            cShift: '',
            hShift: '',
            integration: '',
            splitting: '',
            assignment: '',
          },
        ],
        rowsU1IR: [
          {
            functionalGroup: '',
            waveNumber: '',
            comment: '',
          },
        ],
        rowsU1NMR: [
          {
            cShift: '',
            hShift: '',
            integration: '',
            splitting: '',
            assignment: '',
          },
        ],
        rowsU2IR: [
          {
            functionalGroup: '',
            waveNumber: '',
            comment: '',
          },
        ],
        rowsU2NMR: [
          {
            cShift: '',
            hShift: '',
            integration: '',
            splitting: '',
            assignment: '',
          },
        ],
        rowsU3IR: [
          {
            functionalGroup: '',
            waveNumber: '',
            comment: '',
          },
        ],
        rowsU3NMR: [
          {
            cShift: '',
            hShift: '',
            integration: '',
            splitting: '',
            assignment: '',
          },
        ],
        additionalObservations: '',
      },
      columnsIR: [
        {
          label: 'Functional Group',
          component: STextareaShort,
          prop: 'functionalGroup',
          width: '20%',
        },
        {
          label: 'cm⁻¹',
          component: STextareaShort,
          prop: 'waveNumber',
          width: '15%',
        },
        {
          label: 'Comments',
          component: STextareaShort,
          prop: 'comment',
          width: '65%',
        },
      ],
      columnsNMR: [
        {
          label: '¹³C Shift (δ)',
          component: STextareaShort,
          prop: 'cShift',
          width: '15%',
        },
        {
          label: '¹H Shift (δ)',
          component: STextareaShort,
          prop: 'hShift',
          width: '15%',
        },
        {
          label: '¹H Integration',
          component: STextareaShort,
          prop: 'integration',
          width: '15%',
        },
        {
          label: '¹H Splitting',
          component: STextareaShort,
          prop: 'splitting',
          width: '15%',
        },
        {
          label: 'Assignment',
          component: STextareaShort,
          prop: 'assignment',
          width: '40%',
        },
      ],
      questions: [
        {
          text: 'In the space below, please insert all remaining quantitative observations and calculations for the experiment.',
          inputName: 'additionalObservations',
        },
      ],
      attachments: [] as File[],
    };
  },
  methods: {},
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
